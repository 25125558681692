import React from 'react'
import { TiSocialLinkedinCircular, TiSocialFacebookCircular, TiSocialTwitterCircular } from "react-icons/ti"

const Social = ({location, notitle}) => {
    const postToLinkedIn = () => {
        if (typeof window !== 'undefined') {
            window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(location.href),'', '_blank, width=500, height=500, resizable=yes, scrollbars=yes')
        }
    }
  
    const postToFacebook = () => {
        if (typeof window !== 'undefined') {
            window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(location.href),'', '_blank, width=500, height=500, resizable=yes, scrollbars=yes')
        }
    }
  
    const postToTwitter = () => {
        if (typeof window !== 'undefined') {
            window.open('http://twitter.com/intent/tweet?url=' + encodeURIComponent(location.href),'', '_blank, width=500, height=500, resizable=yes, scrollbars=yes')
        }
    }
    return (
        <>
                    <div className="pt-3 noprint-section">
                        {notitle? "" :
                        <h3 className="pulse heading-box-text2 text-xl md:text-2xl lg:text-3xl mx-8">
                            <span>it's good to share!</span>
                        </h3>
                        }
                      <button rel="noopener" aria-label="Post ths Page to LinkedIn" className="focus:outline-none"
                          onClick={() => { postToLinkedIn() }}>
                          <TiSocialLinkedinCircular className="pulse" size={48} />
                      </button>
                      <button rel="noopener" aria-label="Post this Page to Facebook" className="focus:outline-none"
                          onClick={() => { postToFacebook() }}>
                          <TiSocialFacebookCircular className="pulse" size={48} />
                      </button>
                      <button rel="noopener" aria-label="Post this Page to Twitter" className="focus:outline-none"
                          onClick={() => { postToTwitter() }}>
                          <TiSocialTwitterCircular className="pulse" size={48} /> 
                      </button>
                    </div>
        </>
    )
}

export default Social